import { graphql, PageProps } from 'gatsby';
import {
	AuthorBox,
	Button,
	ImageBox,
	NavBarColorSwitcher,
	NewsletterBox,
	PostHero,
	SharePost,
	Spacer,
	WrapperBody,
} from '../components';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { team } from '../config';
import { getImage } from 'gatsby-plugin-image';
import ContentColumns from '../components/ContentColumns';
import adImage from 'images/blog-ad.png';
import { BlogProps } from './blog-template';
import dayjs from 'dayjs';

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export default function PageTemplate(props: PageProps<BlogProps>) {
	const image = getImage(
		props.data.mdx.frontmatter.image.childImageSharp?.gatsbyImageData
	);

	const author = team.find(
		(member) => member.id === props.data.mdx.frontmatter.author
	);

	return (
		<>
			<PostHero
				category={props.pageContext?.frontmatter?.category}
				categoryUrl={`/blog/category/${props.pageContext?.frontmatter?.category.toLowerCase()}`}
				title={props.pageContext?.frontmatter?.title}
				author={{
					color: 'white',
					name: author?.name ?? '',
					role: author?.position ?? '',
					description: dayjs(
						props.data.mdx.frontmatter.date,
						'YYYY-MM-DD'
					).format('MMM DD, YYYY'),
					avatar: {
						src: author?.avatar ?? '',
						title: author?.name ?? '',
					},
				}}
				image={{
					src: image?.images.fallback?.src ?? '',
					title: props.data.mdx.frontmatter.image_title,
				}}
			/>
			<NavBarColorSwitcher color="dark">
				<Spacer height={80} responsive={{ md: 60, sm: 40 }} />
				<ContentColumns
					columnContent={
						<>
							<NewsletterBox
								title="Newsletter"
								subtitle="From the latest updates and insightful articles to practical guides and case studies! Stay in touch."
								formsparkId="VIeyBxcni"
							/>
							<ImageBox
								title="We make WordPress plugins"
								subtitle="50k+ active uses. Discover WordPress plugins from BracketSpace. Check out our top-rated plugins: Notification and Advanced Cron Manager plugins and more. "
								image={{
									src: adImage,
									title: 'BracketSpace - WordPress Plugins',
								}}
								buttonText="See more"
								to=""
							/>
						</>
					}
				>
					<WrapperBody withSpace={false}>
						<MDXRenderer>{props.data.mdx.body}</MDXRenderer>
					</WrapperBody>
					<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
					<AuthorBox
						author={{
							name: author?.name ?? '',
							avatar: {
								src: author?.avatar ?? '',
								title: author?.name ?? '',
							},
						}}
						role={author?.position}
						description={author?.description}
					/>
					<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
					<SharePost href={`${props.location.href}`} />
					<Spacer height={80} responsive={{ md: 80, sm: 60 }} />
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						{props.pageContext?.previous && (
							<Button to={props.pageContext.previous}>
								Previous
							</Button>
						)}
						{props.pageContext?.next && (
							<Button to={props.pageContext.next}>Next</Button>
						)}
					</div>
				</ContentColumns>
				<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
			</NavBarColorSwitcher>
		</>
	);
}

export const pageQuery = graphql`
	query BLOG_POST($id: String) {
		mdx(id: { eq: $id }) {
			id
			body
			frontmatter {
				slug
				title
				author
				date
				image {
					childImageSharp {
						gatsbyImageData
					}
				}
				image_title
				category
			}
		}
	}
`;
